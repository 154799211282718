var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{staticStyle:{"height":"calc(100% - 55px)","overflow":"auto","padding-bottom":"53px"},attrs:{"title":_vm.titleText[_vm.handleType],"maskClosable":false,"width":"650","placement":"right","visible":_vm.drawerShow,"closable":false},on:{"close":function($event){return _vm.handleClose()}}},[_c('div',[_c('a-steps',{attrs:{"progress-dot":"","current":_vm.currentStep}},[_c('a-step',{attrs:{"title":"基本信息"}}),_c('a-step',{attrs:{"title":"培训时段信息"}})],1)],1),_c('a-divider'),(_vm.currentStep === 0)?_c('div',[_c('a-form-model',{ref:"ruleForm",attrs:{"validateOnRuleChange":true,"model":_vm.form,"rules":_vm.formRules,"form":_vm.form,"label-col":{ span: 3 },"wrapper-col":{ span: 18 }}},[_c('a-form-model-item',{attrs:{"label":"课程编号","prop":"serialNumber"}},[_c('a-input',{attrs:{"placeholder":"输入课程编号"},model:{value:(_vm.form.serialNumber),callback:function ($$v) {_vm.$set(_vm.form, "serialNumber", $$v)},expression:"form.serialNumber"}})],1),_c('a-form-model-item',{attrs:{"label":"课程名称","prop":"title"}},[_c('a-input',{attrs:{"placeholder":"输入课程名称"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('a-form-model-item',{attrs:{"label":"课程介绍","prop":"introduce"}},[_c('a-textarea',{attrs:{"placeholder":"输入课程介绍","rows":5},model:{value:(_vm.form.introduce),callback:function ($$v) {_vm.$set(_vm.form, "introduce", $$v)},expression:"form.introduce"}})],1),_c('a-form-model-item',{attrs:{"label":"培训形式","prop":"trainingMethod"}},[_c('a-select',{attrs:{"mode":"default","disabled":"","option-label-prop":"label","value":_vm.form.trainingMethod,"placeholder":"选择培训形式"},on:{"change":function (val) {
                _vm.form.trainingMethod = val;
              }}},_vm._l((_vm.trainingMethodData),function(r,index){return _c('a-select-option',{key:index,attrs:{"value":r.id,"label":r.value}},[_vm._v(_vm._s(r.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":"类别","prop":"trainingType"}},[_c('a-select',{attrs:{"allowClear":"","mode":"default","option-label-prop":"label","value":_vm.form.trainingType,"placeholder":"选择类别"},on:{"change":function (val) {
                _vm.form.trainingType = val;
              }}},_vm._l((_vm.trainingTypeData),function(r,index){return _c('a-select-option',{key:index,attrs:{"value":r.id,"label":r.value}},[_vm._v(_vm._s(r.value))])}),1)],1),(_vm.handleType)?_c('a-form-model-item',{attrs:{"label":"状态","prop":"status"}},[_c('a-select',{attrs:{"placeholder":"选择是否完成"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.status),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":val.keyy}},[_vm._v(_vm._s(val.valuee))])}),1)],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":"评估方式","prop":"appraiseType"}},[_c('a-select',{attrs:{"allowClear":"","mode":"default","option-label-prop":"label","value":_vm.form.appraiseType,"placeholder":"选择评估方式"},on:{"change":function (val) {
                _vm.form.appraiseType = val;
              }}},_vm._l((_vm.appraiseTypeData),function(r,index){return _c('a-select-option',{key:index,attrs:{"value":r.id,"label":r.value}},[_vm._v(_vm._s(r.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":"培训资料","prop":"fileId"}},[_c('a-select',{attrs:{"mode":"default","show-search":"","option-label-prop":"label","value":_vm.form.fileId,"placeholder":"选择培训资料","filter-option":_vm.filterFile,"option-filter-prop":"children"},on:{"change":function (val) {
                _vm.form.fileId = val;
              }}},_vm._l((_vm.filesData),function(r,index){return _c('a-select-option',{key:index,attrs:{"value":r.id,"label":r.fileName}},[_vm._v(_vm._s(r.fileName))])}),1)],1),_c('a-form-model-item',{attrs:{"label":"外链地址","prop":"examUrl"}},[_c('a-input',{attrs:{"placeholder":"输入有效的外链地址"},model:{value:(_vm.form.examUrl),callback:function ($$v) {_vm.$set(_vm.form, "examUrl", $$v)},expression:"form.examUrl"}})],1),_c('a-form-model-item',{attrs:{"label":"培训对象","prop":"rules"}},[_c('user-rule-creator',{ref:"userRuleCreator",on:{"valueChange":_vm.rulesValueChange}})],1)],1)],1):_vm._e(),(_vm.currentStep === 1)?_c('training-course',{ref:"course",attrs:{"handleType":_vm.handleType,"trainingId":Number(_vm.form.id)},on:{"handleClose":function($event){return _vm.handleClose()}}}):_vm._e(),_c('div',{staticClass:"drawer-bootom-button"},[_c('a-button',{staticStyle:{"margin-right":"0.8rem"},on:{"click":function($event){return _vm.handleClose()}}},[_vm._v("取消")]),(_vm.currentStep === 0)?_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("下一步")]):_vm._e(),(_vm.currentStep > 0)?_c('a-button',{on:{"click":_vm.handlePre}},[_vm._v("上一步")]):_vm._e(),(_vm.currentStep > 0)?_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.courseSubmit}},[_vm._v(_vm._s(_vm.handleType === 0 ? "添加" : "编辑"))]):_vm._e()],1),_c('user-group-info',{ref:"userGroupInfo"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }